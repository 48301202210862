import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { getNamesInLineFromKebabCase } from "functions"

// Model
import { ISong } from "models/Song"
// Styles
import * as styles from "./SongItem.module.css"

type SongItemPros = {
  song: ISong
}

const SongItem: React.FC<SongItemPros> = ({ song }) => {
  const { title, date, thumbnail, authors } = song.frontmatter
  const { slug } = song.fields
  const image = thumbnail ? getImage(thumbnail.childImageSharp.gatsbyImageData) : null
  const authors_names = getNamesInLineFromKebabCase(authors)

  return (
    <Link className={styles.song_item} to={`/songs${slug}`}>
      {image && <GatsbyImage className={styles.song_item__thumbnail} image={image} alt={title} />}
      <div className={styles.song_item__info}>
        <h3 className={styles.song_item__title}>
          {title} - {authors_names}
        </h3>
        <small className={styles.song_item__date}>Traducción publicada el {date}</small>
      </div>
    </Link>
  )
}

export default SongItem
