import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type PaginationProps = {
  url: string
  title?: string
  currentPage: number
  totalPages: number
}

const Pagination: React.FC<PaginationProps> = ({ url, title, currentPage, totalPages }) => {
  const hasRecent = currentPage > 1
  const hasPrevious = currentPage < totalPages

  return (
    <section className="pagination">
      <div className="pagination_button">
        {hasRecent && (
          <Link className="pagination_left" to={`${url}/${currentPage - 1 === 1 ? "" : currentPage - 1}`}>
            <FontAwesomeIcon icon={["fas", "chevron-left"]} /> {title ? `${title} más recientes` : "Siguiente"}
          </Link>
        )}
      </div>

      <div className="pagination_button">
        {hasPrevious && (
          <Link className="pagination_right" to={`${url}/${currentPage + 1}`}>
            {title ? `${title} anteriores` : "Anterior"} <FontAwesomeIcon icon={["fas", "chevron-right"]} />
          </Link>
        )}
      </div>
    </section>
  )
}

export default Pagination
