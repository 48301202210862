import * as React from "react"
import { graphql, PageProps } from "gatsby"

// Components
import Layout from "components/Layout"
import Main from "components/Layout/Main"
import SongItem from "components/Sections/Songs/SongItem"
import Pagination from "components/UIElements/Pagination"
import SongsSidebar from "components/Sections/Songs/SongsSidebar"
// Models
import { ISong } from "models/Song"
import { IArtist } from "models/Artist"
import { IOptionalMetaProps } from "models/Seo"

type DataProps = {
  songs: {
    totalCount: number
    nodes: ISong[]
  }
  artists: {
    nodes: IArtist[]
  }
}

type PageContextProps = {
  currentPage: number
  totalPages: number
}

const SongsListPage: React.FC<PageProps<DataProps, PageContextProps>> = ({ data, pageContext }) => {
  const { currentPage, totalPages } = pageContext
  const songs = data.songs.nodes
  const artists = data.artists.nodes

  const seo: IOptionalMetaProps = {
    title: "Canciones",
    description: "Lista de canciones que hemos traducido y publicado en la página",
  }

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <h2 className="title">Canciones traducidas</h2>
        <div>
          {songs.map(song => (
            <SongItem key={song.fields.slug} song={song} />
          ))}
        </div>
        <Pagination url="/songs" title="Traducciones" currentPage={currentPage} totalPages={totalPages} />
      </Main>
      <SongsSidebar count={data.songs.totalCount} artists={artists} />
    </Layout>
  )
}

export default SongsListPage

export const pageQuery = graphql`
  query SongsListQuery($limit: Int!, $skip: Int!) {
    songs: allMdx(
      filter: { fields: { collection: { eq: "songs" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        fields {
          slug
          author_name
          author_slug
        }
        frontmatter {
          title
          authors
          date(formatString: "DD [de] MMMM, YYYY", locale: "es")
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 150, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
    artists: allArtist(filter: { hide: { ne: true } }, limit: 5) {
      totalCount
      nodes {
        name
        slug
        avatar {
          childImageSharp {
            gatsbyImageData(width: 100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`
