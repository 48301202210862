import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, useStaticQuery } from "gatsby"

// Model
import { ISite } from "models/Seo"
// Styles
import * as styles from "./Follow.module.css"

const Follow: React.FC = () => {
  const { site }: ISite = useStaticQuery(query)

  return (
    <>
      <nav className={styles.follow}>
        <a href={`https://www.twitter.com/${site.siteMetadata.twitter}`} target="_blank" rel="noopener noreferrer">
          <div className={styles.follow_social} data-social="twitter">
            <FontAwesomeIcon icon={["fab", "twitter"]} />
            <span>Twitter</span>
          </div>
        </a>
        <a href={`https://fb.me/${site.siteMetadata.facebook}`} target="_blank" rel="noopener noreferrer">
          <div className={styles.follow_social} data-social="facebook">
            <FontAwesomeIcon icon={["fab", "facebook"]} />
            <span>Facebook</span>
          </div>
        </a>
        <a href={`https://www.instagram.com/${site.siteMetadata.instagram}`} target="_blank" rel="noopener noreferrer">
          <div className={styles.follow_social} data-social="instagram">
            <FontAwesomeIcon icon={["fab", "instagram"]} />
            <span>Instagram</span>
          </div>
        </a>
        <a href={`${site.siteMetadata.youtube}`} target="_blank" rel="noopener noreferrer">
          <div className={styles.follow_social} data-social="youtube">
            <FontAwesomeIcon icon={["fab", "youtube"]} />
            <span>YouTube</span>
          </div>
        </a>
      </nav>
    </>
  )
}

export default Follow

const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
        twitter
        facebook
        instagram
        youtube
      }
    }
  }
`
