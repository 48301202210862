import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components
import Sidebar from "components/Layout/Sidebar"
import Follow from "components/UIElements/Follow"
// Model
import { IArtist } from "models/Artist"

type SongsSidebarProps = {
  artists: IArtist[]
  count: number
}

const SongsSidebar: React.FC<SongsSidebarProps> = ({ artists, count }) => {
  return (
    <Sidebar>
      <h2 className="title">Artistas</h2>
      <section className={"sidebar_list"}>
        {artists.map(artist => {
          const artistAvatar = getImage(artist.avatar.childImageSharp.gatsbyImageData)
          return (
            <Link key={artist.slug} className={"sidebar_list__element"} to={`/artists/${artist.slug}/songs`}>
              {artistAvatar && (
                <GatsbyImage
                  loading="eager"
                  className={"sidebar_list__element_avatar"}
                  image={artistAvatar}
                  alt={artist.name}
                />
              )}
              <h3 className={"sidebar_list__element_title"}>{artist.name}</h3>
            </Link>
          )
        })}
      </section>
      <Link to={`/artists`} className={"sidebar_button"}>
        Ver todos
      </Link>
      <hr />
      <section className="sidebar_card_alt">{count} traducciones publicadas</section>
      <hr />
      <section className={"sidebar_card_alt"}>
        <h2 className="title">Síguenos</h2>
        <Follow />
      </section>
    </Sidebar>
  )
}

export default SongsSidebar
